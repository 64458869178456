var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-large"},[_c('div',{staticClass:"progress-large__left"},[_c('div',{staticClass:"progress-large__labels"},[_c('div',{staticClass:"progress-status",class:{
        'progress-status--doing': _vm.progressStatus === _vm.ActivityStatuses.doing,
        'progress-status--done': _vm.progressStatus === _vm.ActivityStatuses.done,
        'progress-status--not-started': _vm.progressStatus === _vm.ActivityStatuses.notStarted,
      }},[_vm._v(_vm._s(_vm.status))]),_c('div',{staticClass:"progress-counter"},[_vm._v(" "+_vm._s(`${_vm.score} / ${_vm.maxScore} `)+_vm._s(_vm.$tc('label.pointsPluralRod', _vm.maxScore))+" ")])]),_c('div',{staticClass:"lms-progress-bar",class:{
        'lms-progress-bar--doing': _vm.progressStatus === _vm.ActivityStatuses.doing,
        'lms-progress-bar--done': _vm.progressStatus === _vm.ActivityStatuses.done,
        'lms-progress-bar--not-started': _vm.progressStatus === _vm.ActivityStatuses.notStarted,
      }},[_c('b-progress',{attrs:{"value":_vm.percent,"max":100}})],1)]),_c('div',{staticClass:"progress-large__right"},[_c('span',[_vm._v(" "+_vm._s(_vm.percent)+"% ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }