<template>
  <div class="tag-filter">
    <div name="list-tag" class="tags-select__control">
      <TagBadge
        v-for="tag in tagsToShow"
        :key="tag.id"
        :title="tag.name"
        :tag="tag"
        :isEditable="false"
        isDisabled
      />

      <TagBadge
        v-if="hiddenTagsCount > 0 || areAllTagsVisible"
        key="more"
        variant="secondary"
        :title="showMoreButtonLabel"
        :isEditable="false"
        isDisabled
        class="cursor-pointer"
        @clickSuffix="areAllTagsVisible = !areAllTagsVisible"
        @click="areAllTagsVisible = !areAllTagsVisible"
      >
        <template #suffixIcon>
          <b-icon :icon="icon" />
        </template>
      </TagBadge>
    </div>
  </div>
</template>

<script>
import deviceMixin from '@/mixins/device.mixin';
import TagBadge from '@/components/Tag/TagBadge.vue';

export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TagBadge,
  },
  mixins: [deviceMixin],
  data() {
    return {
      isOverflowing: false,
      areAllTagsVisible: false,
    };
  },
  methods: {},
  computed: {
    tagsToShowCount() {
      return this.tags.reduce(
        (summaries, tag) => {
          if (summaries.summaryWidth < (this.$device.isMobile ? 343 : 1060)) {
            // ~5.7px to 1 symbol on mobile and ~14px to 1 symbol on desktop
            summaries.summaryWidth += tag.name.length * (this.$device.isMobile ? 5.7 : 14);
            summaries.count += 1;
          }
          return summaries;
        },
        { summaryWidth: 0, count: 0 },
      ).count;
    },
    tagsToShow() {
      return this.areAllTagsVisible ? this.tags : this.tags.slice(0, this.tagsToShowCount);
    },
    hiddenTagsCount() {
      return this.tags.length - this.tagsToShow.length;
    },
    showMoreButtonLabel() {
      return this.areAllTagsVisible
        ? this.$t('buttonLabels.less')
        : `${this.$t('buttonLabels.more')} ${this.hiddenTagsCount}`;
    },
    icon() {
      return !this.areAllTagsVisible ? 'chevron-down' : 'chevron-up';
    },
  },
};
</script>
